import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import AboutUs from './Components/AboutUs';
import Contact from './Components/Contact';
import Course from './Components/Course';

function App() {
  return (
  <>
  <BrowserRouter>
  <Routes>
    <Route 
    path='/' 
    element={<><Header/><Home/><Footer/></>}/>
    <Route 
    path='/aboutus' 
    element={<><Header/><AboutUs/><Footer/></>}/>
    <Route 
    path='/contact' 
    element={<><Header/><Contact/><Footer/></>}/>
    <Route 
    path='/course' 
    element={<><Header/><Course/><Footer/></>}/>
  </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
