import React from 'react'

function Contact() {
  return (
    <>
    {/* Header Start */}
    <div 
   style={{ 
    background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`, 
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

}}
    className="container-fluid bg-primary py-5 mb-5 page-header">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <h1 className="display-3 text-white animated slideInDown">Contact</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a className="text-white" href="/">Home</a>
                </li>
                <li className="breadcrumb-item text-white active" aria-current="page">Contact</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
    {/* Header End */}

    {/* Contact Start */}
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="mb-5 bg-white text-center px-3">Contact Us</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <h5>Get In Touch</h5>
            <p className="mb-4">
              I'm happy to help! If you're looking for contact information or details about SecretCoder's online free courses website for e-learning, I don't have real-time browsing capabilities to access current websites or specific contact details.
            </p>
            <div className="d-flex align-items-center mb-3">
              <div className="d-flex align-items-center justify-content-center flex-shrink-0" style={{ width: '50px', height: '50px', backgroundColor: '#fb873f' }}>
                <i className="fa fa-map-marker-alt text-white"></i>
              </div>
              <div className="ms-3">
                <h5>Office</h5>
                <p className="mb-0">singapura, Bangalore, Karnataka</p>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="d-flex align-items-center justify-content-center flex-shrink-0" style={{ width: '50px', height: '50px', backgroundColor: '#fb873f' }}>
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <div className="ms-3">
                <h5>Mobile</h5>
                <p className="mb-0">+91 8683045908</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-center flex-shrink-0" style={{ width: '50px', height: '50px', backgroundColor: '#fb873f' }}>
                <i className="fa fa-envelope-open text-white"></i>
              </div>
              <div className="ms-3">
                <h5>Email</h5>
                <p className="mb-0">contact@gmail.com</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
            <form>
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="name" required placeholder="Your Name" />
                    <label htmlFor="name">Your Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input type="email" className="form-control" id="email" required placeholder="Your Email" />
                    <label htmlFor="email">Your Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="subject" required placeholder="Subject" />
                    <label htmlFor="subject">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea className="form-control" required placeholder="Leave a message here" id="message" style={{ height: '150px' }}></textarea>
                    <label htmlFor="message">Message</label>
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" type="submit">
                    <a href="mailto:keertidvcorai@gmail.com" className="text-decoration-none" style={{ color: '#fff' }}>Send Message</a>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* Contact End */}
  </>
  )
}

export default Contact