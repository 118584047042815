import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Style/navbar.css";
function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarFixed, setNavbarFixed] = useState(false);

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close menu when a link is clicked
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  // Add scroll event listener to fix navbar on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarFixed(true);
      } else {
        setNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
<header>
  <nav
    className={`navbar navbar-expand-lg navbar-light bg-light ${
      navbarFixed ? "navbar-fixed" : ""
    }`}
  >
    <div className="container-fluid d-flex justify-content-between align-items-center">
      {/* Logo and project name on the left side */}
      <div className="navbar-brand logotransform d-flex align-items-center">
        <img src="./img/logo.png" alt="internship" className="logo-img" />
        <span className="ms-2 project-name">INTERNSHIPWALA</span>
      </div>

      {/* Toggle button for mobile view - Now aligned to the right */}
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleMobileMenu}
      >
        <i
          className={`fas ${isMobileMenuOpen ? "fa-times" : "fa-bars"}`}
        ></i>
      </button>

      {/* Menu items */}
      <div
        className={`collapse navbar-collapse ${
          isMobileMenuOpen ? "show" : ""
        } justify-content-end`}
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={closeMobileMenu}>
              HOME
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/aboutus" className="nav-link" onClick={closeMobileMenu}>
              ABOUT US
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/course" className="nav-link" onClick={closeMobileMenu}>
              COURSE
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link" onClick={closeMobileMenu}>
              CONTACT US
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
    </div>
  );
}

export default Header;
