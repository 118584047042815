import React from "react";
import OwlCarousel from "react-owl-carousel";
import "./Style/homepage.css";
// import './Style/navbar.css'
function Home() {
  const categories = [
    { name: "Microsoft Excel", imgSrc: "img/cat1.png" },
    { name: "AWS", imgSrc: "img/cat2.png" },
    { name: "Python", imgSrc: "img/cat3.png" },
    { name: "Java", imgSrc: "img/cat4.png" },
    { name: "Web Design", imgSrc: "img/cat5.png" },
    { name: "Web Development", imgSrc: "img/cat6.png" },
    { name: "MySQL", imgSrc: "img/cat7.png" },
    { name: "UI/UX Design", imgSrc: "img/cat8.png" },
  ];
  return (
    <div>
      <div className="container-fluid p-0 mb-4">
        <OwlCarousel
          className="owl-carousel header-carousel position-relative"
          items={1}
          loop
          autoplay
          autoplayTimeout={5000}
          animateOut="fadeOut"
          animateIn="fadeIn"
          nav={false}
          dots={true}
        >
          {/* Carousel Item 1 */}
          <div className="owl-carousel-item position-relative">
            <img
              className="img-fluid"
              src="img/carousel-1.jpg"
              alt="Carousel 1"
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5
                      className="text-uppercase mb-3 animated slideInDown"
                      style={{ color: "#fb873f" }}
                    >
                      Best E-learning platform
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                      Learn Job ready skills from free online courses with
                      certificates
                    </h1>
                    <p className="text-white mb-4 pb-2">
                      Explore a wide range of courses designed to enhance your
                      expertise in technology, business, arts, and more. Start
                      learning today!
                    </p>
                    <a
                      href="
                      /aboutus"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href="#"
                      className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Carousel Item 2 */}
          <div className="owl-carousel-item position-relative">
            <img
              className="img-fluid"
              src="img/carousel-2.jpg"
              alt="Carousel 2"
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(24, 29, 56, .7)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-sm-10 col-lg-8">
                    <h5
                      className="text-uppercase mb-3 animated slideInDown"
                      style={{ color: "#fb873f" }}
                    >
                      Welcome to Secret Coder
                    </h5>
                    <h1 className="display-3 text-white animated slideInDown">
                      Interactive Learning Experience
                    </h1>
                    <p className="text-white mb-4 pb-2">
                      Engage with interactive lessons, quizzes, and projects.
                      Experience hands-on learning that keeps you motivated and
                      inspired.
                    </p>
                    <a
                      href="/aboutus"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Read More
                    </a>
                    <a
                      href="signup.html"
                      className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                    >
                      Join Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          {/* Header section */}
          <div className="row g-2 text-center">
            <div className="col-12 wow fadeInUp" data-wow-delay="0.3s">
              <h1 style={{ color: "#fb873f" }}>
                Invest in your professional goals with Secret Coder
              </h1>
              <p className="mb-5">
                Get unlimited access to over 90% of courses, Projects,
                Specializations, and Professional Certificates on Coursera,
                taught by top instructors.
              </p>
            </div>
          </div>

          {/* Service items */}
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item text-center pt-3 shadow">
                <div className="p-4">
                  <img
                    src="img/icon1.png"
                    alt="Learn anything"
                    width="60px"
                    className="mb-4"
                  />
                  <h5 className="mb-3">Learn anything</h5>
                  <p>
                    Explore any interest or trending topic, take prerequisites,
                    and advance your skills
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item text-center pt-3 shadow">
                <div className="p-4">
                  <img
                    src="img/icon2.png"
                    alt="Save money"
                    width="60px"
                    className="mb-4"
                  />
                  <h5 className="mb-3">Save money</h5>
                  <p>
                    Spend less money on your learning if you plan to take
                    multiple courses this year
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item text-center pt-3 shadow">
                <div className="p-4">
                  <img
                    src="img/icon3.png"
                    alt="Flexible learning"
                    width="60px"
                    className="mb-4"
                  />
                  <h5 className="mb-3">Flexible learning</h5>
                  <p>
                    Learn at your own pace, move between multiple courses, or
                    switch to a different course
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item text-center pt-3 shadow">
                <div className="p-4">
                  <img
                    src="img/icon4.png"
                    alt="Unlimited certificates"
                    width="60px"
                    className="mb-4"
                  />
                  <h5 className="mb-3">Unlimited certificates</h5>
                  <p>
                    Earn a certificate for every learning program that you
                    complete at no additional cost
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            {/* Image Section */}
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/about.jpg"
                  alt="About Internshipwala"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>

            {/* Text Content Section */}
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start pe-3">
                About Us
              </h6>
              <h1 className="mb-4" style={{ color: "#fb873f" }}>
                Welcome to Internshipwala
              </h1>
              <p className="mb-4">
                At Internshipwala, we believe in accessible, innovative learning
                experiences that adapt to your schedule and learning style. Join
                us in embracing the future of education and unlock your
                potential with our immersive online courses.
              </p>
              <p className="mb-4">
                Welcome to Internshipwala, your gateway to boundless learning
                opportunities. We're dedicated to democratizing education,
                offering a diverse range of courses taught by industry experts.
                Our mission is to empower learners worldwide, fostering a
                community-driven platform where knowledge knows no limits.
              </p>

              {/* List of Features */}
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Expert Instructors
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Live Interactive
                    Sessions
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Comprehensive
                    Course Catalog
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Community
                    Engagement
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Personalized
                    Learning Paths
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right me-2"></i>Certification and
                    Recognition
                  </p>
                </div>
              </div>

              {/* Read More Button */}
              <a className="btn text-light py-3 px-5 mt-2" href="/aboutus">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5 pt-5 bg-light">
        <div className="container">
          <div className="row g-5">
            {/* Text Section */}
            <div className="col-lg-6 p-5 wow fadeInUp" data-wow-delay="0.3s">
              <h1 className="mb-4" style={{ color: "#fb873f" }}>
                Explore Free Courses
              </h1>
              <p className="mb-4">
                Start your online learning journey at Great Learning Academy for
                free with our short-term basic courses across various in-demand
                domains.
              </p>
              <a className="btn text-light py-3 px-5 mt-2" href="signup.html">
                Start For Free
              </a>
            </div>

            {/* Image Section */}
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/banner-1.jpg"
                  alt="Explore Free Courses"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5 category">
        <div className="container">
          {/* Title Section */}
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center px-3">
              Categories
            </h6>
            <h1 className="mb-5" style={{ color: "#fb873f" }}>
              Popular Topics to Explore
            </h1>
          </div>

          {/* Categories Grid */}
          <div className="row g-2 m-2">
            {categories.map((category, index) => (
              <div className="col-lg-3 col-md-6 text-center" key={index}>
                <div
                  className="content shadow p-3 mb-2 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <img
                    src={category.imgSrc}
                    className="img-fluid"
                    alt={category.name}
                  />
                  <h5 className="my-2">
                    <a href="#" className="text-center">
                      {category.name}
                    </a>
                  </h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center px-3">
              Popular Courses
            </h6>
            <h1 className="mb-5" style={{color: "#fb873f"}}>
              Explore new and trending free online courses
            </h1>
          </div>
          <div class="row g-4 py-2">
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="course-item shadow">
              <div className="position-relative overflow-hidden text-light image">
                <img className="img-fluid" src="img/course-1.jpg" alt="" />
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "16px",
                    fontSize: "12px",
                    borderRadius: "3px",
                    backgroundColor: "#fb873f",
                  }}
                  className="px-2 py-1 fw-bold text-uppercase"
                >
                  FREE
                </div>
              </div>
              <div className="p-2 pb-0">
                <h5 className="mb-1">
                  <a href="#" className="text-dark">
                    HTML Course for Beginners
                  </a>
                </h5>
              </div>
              <div className="d-flex">
                <small className="flex-fill text-center py-1 px-2">
                  <i className="fa fa-star text-warning me-2"></i>4.55
                </small>
                <small className="flex-fill text-center py-1 px-2">
                  <i className="fa fa-user-graduate me-2"></i>5.8L+ Learners
                </small>
                <small className="flex-fill text-center py-1 px-2">
                  <i className="fa fa-user me-2"></i>Beginner
                </small>
              </div>
              <div className="d-flex">
                <small className="flex-fill text-left p-2 px-2">
                  <i className="fa fa-clock me-2"></i>2.0 Hrs
                </small>
                <small className="py-1 px-2 fw-bold fs-6 text-center">₹ 0</small>
                <small className="text-primary py-1 px-2 fw-bold fs-6" style={{ float: "right" }}>
                  <a href="#">Enroll Now</a>
                  <i className="fa fa-chevron-right me-2 fs-10"></i>
                </small>
              </div>
            </div>
          </div>
            
          </div>
        </div>
      </div>
      <div class="container text-center">
        <a class="btn text-light py-3 px-5 mt-2 mb-5" href="courses.html">
          All Courses
        </a>
      </div>


      <section className="pb-5" style={{ background: "url('img/banner-3.jpg')" }}>
      <div className="container-xxl mt-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100 bg-light"
                  src="img/banner-2.png"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>

            <div className="col-lg-6 p-5 wow fadeInUp" data-wow-delay="0.3s">
              <h1 className="mb-4" style={{ color: "#fb873f" }}>
                Become an Instructor
              </h1>
              <p className="mb-4 text-white">
                Instructors from around the world teach millions of learners on Udemy. We provide the tools and skills to teach what you love.
              </p>
              <a className="btn text-light py-3 mt-2" href="instructor.html">
                Start Teaching Today
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="container-xxl py-5 category">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="mb-5">Frequently Asked Questions</h1>
        </div>
        <div className="row g-2">
          <div className="col-12">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What is the Secret Coder?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    The Secret Coder is an initiative taken by Secret Coder, where we offer 1000+ free online courses in cutting-edge technologies and have successfully enrolled a whopping 5 Million+ learners across all domains. Secret Coder covers courses on Data Science, Machine Learning, Artificial Intelligence, Cloud Computing, Software Development, Sales and Business Development, Digital Marketing, Big Data, and many more.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Why should I choose Great Learning Academy for free courses with certificates?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Great Learning Academy is an excellent choice for free courses with certificates because of the high quality of the learning content. The courses are well-crafted, offer a great learning experience, and are interactive and engaging, making them ideal for learners in identifying what works best for their career goals.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How many free courses can I enroll in at the same time?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You can simultaneously enroll in multiple courses at Secret Coder.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    How can I enroll in these free online courses?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    You can click on the “Sign Up” button at the top-right of the page and register with your email address, or you can sign up using your Google Account.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    What are the most popular free courses offered by Secret Coder?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Secret Coder focuses on in-demand concepts and skills, where learners can develop industry-relevant knowledge in their chosen sector. It provides a wide range of courses in prestigious fields, assisting learners across the globe in achieving their professional goals.

                    <p>Some of the most popular free courses offered by Secret Coder that are in high demand today include:</p>
                    <ul>
                      <li>Free Data Science Courses</li>
                      <li>Free Artificial Intelligence Courses</li>
                      <li>Free Software Courses</li>
                      <li>Free Cloud Computing Courses</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Home;
