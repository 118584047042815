import React from "react";

function AboutUs() {
  return (
    <div>
      {/* Header Start */}
      <div
        style={{
          background: `linear-gradient(rgba(24, 29, 56, 0.7), rgba(24, 29, 56, 0.7)), url("img/carousel-1.jpg")`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="container-fluid bg-primary py-5 mb-5 page-header"
      >
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                About Us
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* Header End */}

      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start pe-3">
                About Us
              </h6>
              <h1 className="mb-4" style={{ color: "#fb873f" }}>
                Welcome to Secret Coder
              </h1>
              <p className="mb-4">
                At Secret Coder, we believe in accessible, innovative learning
                experiences that adapt to your schedule and learning style. Join
                us in embracing the future of education and unlock your
                potential with our immersive online courses.
              </p>
              <p className="mb-4">
                Welcome to Secret Coder, where learning knows no boundaries. Our
                mission is to empower individuals worldwide through accessible
                and innovative education. Here's what sets us apart:
              </p>

              <h3 className="mb-4">Our Vision</h3>
              <p className="mb-4">
                At Secret Coder, we envision a world where learning is
                accessible to everyone, regardless of their location,
                background, or circumstances. We strive to break barriers and
                make education a transformative and inclusive experience for
                all.
              </p>

              <h3 className="mb-4">A Commitment to Excellence</h3>
              <p className="mb-4">
                We are dedicated to providing top-quality education. Our team
                collaborates with industry experts and educators to curate
                courses that meet the highest standards, ensuring our learners
                receive valuable and up-to-date knowledge.
              </p>

              <h3 className="mb-4">Empowering Learners</h3>
              <p className="mb-4">
                We believe in the transformative power of education. Secret
                Coder is designed to empower individuals to pursue their
                passions, advance their careers, and acquire new skills in a
                dynamic and supportive environment.
              </p>

              <h3 className="mb-4">Innovation in Learning</h3>
              <p className="mb-4">
                Embracing technology, we offer innovative learning methods and
                tools. From interactive modules to live sessions, we're
                committed to providing a cutting-edge learning experience that
                fosters engagement and knowledge retention.
              </p>

              <h3 className="mb-4">Community-Centric Approach</h3>
              <p className="mb-4">
                Secret Coder is more than just courses; it's a vibrant
                community. We encourage collaboration, discussion, and knowledge
                sharing among learners, fostering an environment of growth and
                camaraderie.
              </p>

              <h3 className="mb-4">Diverse and Inclusive Education</h3>
              <p className="mb-4">
                We celebrate diversity in perspectives, cultures, and ideas. Our
                diverse course offerings cater to various interests and skill
                levels, ensuring that everyone finds a learning path that
                resonates with them.
              </p>

              <h3 className="mb-4">Continuous Improvement</h3>
              <p className="mb-4">
                We are always evolving. Feedback from our learners helps us
                continually enhance our platform, ensuring it remains dynamic,
                responsive, and aligned with the evolving needs of our users.
              </p>

              <p className="mt-5 mb-4">
                Thank you for being a part of Secret Coder. Together, let's
                embark on a journey of lifelong learning and growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
